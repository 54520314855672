import React from 'react'

import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import theme from '../helpers/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  * {
    padding: 2px;
  }
`

const HomeButton = styled(Button)`
  margin: 5px !important;
  background-color: ${theme.colors.green} !important;
  border-color: unset !important;
  font-family: 'Open Sans' !important;
`

const NotFoundPage = () => (
  <Layout>
      <Container>
        <SEO title='404: Not found' />
        <h1>404</h1>
        <h2>Page not found</h2>
        <p>The page you requested could not be found.</p>
        <HomeButton
          href='/'
          dataElementLocation={DataElementLocations.BODY}
          dataElementLabel={'404-sitey'}
          dataElementId={'404-sitey-button'}
          variant='contained'
          size='large'>
            Home
        </HomeButton>
    </Container>
  </Layout>
)

export default NotFoundPage
